<template>
    <CRow>
      <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
      <CCol sm="12">
        <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <CRow>
            <CCol sm="8" class="d-flex align-items-start ">{{ $t('label.nueva')+' '+$t('label.newShippingLine') }} 
            
            </CCol>
            <CCol sm="4" class="d-flex align-items-center justify-content-end">
              <button type="button" aria-label="Close" class="close" @click="resetForm">×</button>
            </CCol>
          </CRow>
        </CCardHeader>
          <CCardBody>
            <CRow class="mb-2">
              <CCol sm="4">
                <CRow>
                  <CCol sm="12">
                    <div class="preview">    
                      <picture-input    
                        ref="imageInput"
                        :imgSrc="image"
                        width="250"
                        height="200"
                        accept="image/jpeg,image/png, image"
                        size="10" 
                        :custom-strings="imgInputTexts"
                        @change="handleFileUpload"
                      >
                      </picture-input>
                      <div v-if="$v.linea.LineRoute.$error">
                        <div class='text-invalid-feedback' v-if="!$v.linea.LineRoute.isValidFile">{{$t('label.badImage')}}</div>
                      </div>
                    </div>
                  </Ccol>
                </CRow>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.linea.ShippingLineName.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.linea.ShippingLineName)"
                      :is-valid="hasError($v.linea.ShippingLineName)"
                      size="sm"
                      :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                      :label="$t('label.name')"
                      addLabelClasses="required text-right"
                      maxlength="50"
                      :placeholder="$t('label.newShippingLineName')" 
                    />
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.linea.PrimaryPhone.$model"
                      :invalid-feedback="errorMessage($v.linea.PrimaryPhone)"
                      :is-valid="hasError($v.linea.PrimaryPhone)"
                      size="sm"
                      :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                      type="tel"
                      :label="$t('label.mainPhone')"
                      addLabelClasses="text-right text-right"
                      maxlength="15"
                      minlength="11"
                      :placeholder="$t('label.mainPhone')" 
                    />
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.linea.SecondaryPhone.$model"
                      :invalid-feedback="errorMessage($v.linea.SecondaryPhone)"
                      :is-valid="hasError($v.linea.SecondaryPhone)"
                      size="sm"
                      :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                      type="tel"
                      :label="$t('label.secondaryPhone')"
                      addLabelClasses="text-right"
                      maxlength="15"
                      minlength="11"
                      :placeholder="$t('label.secondaryPhone')" 
                    />
                  </CCol>
                  <CCol sm="12">
                     <CInput
                      v-model.trim="$v.linea.PrimaryEmail.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.linea.PrimaryEmail)"
                      :is-valid="hasError($v.linea.PrimaryEmail)"
                      size="sm"
                      :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                      type="email"
                      :label="$t('label.mainEmail')"
                      addLabelClasses="text-right"
                      maxlength="100"
                      :placeholder="$t('label.mainEmail')" 
                    />
                  </CCol>
                  <CCol sm="12">
                     <CInput
                      v-model.trim="$v.linea.SecondaryEmail.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.linea.SecondaryEmail)"
                      :is-valid="hasError($v.linea.SecondaryEmail)"
                      size="sm"
                      :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                      type="email"
                      :label="$t('label.secondaryEmail')"
                      addLabelClasses="text-right"
                      maxlength="100"
                      :placeholder="$t('label.secondaryEmail')" 
                    />
                  </CCol>              
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="4">
                <CRow>
                  <CCol sm="12">
                      <CInput
                      :label="$t('label.color')"
                      addLabelClasses="required text-right"
                      v-uppercase
                      type="color"
                      size="sm"
                      :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                      v-model="$v.linea.Color.$model"
                      :is-valid="hasError($v.linea.Color)"
                      :invalid-feedback="errorMessage($v.linea.Color)"
                      required
                    />  
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.linea.ShippingLineAbbrev.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.linea.ShippingLineAbbrev)"
                      :is-valid="hasError($v.linea.ShippingLineAbbrev)"
                      size="sm"
                      :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                      :label="$t('label.acronym')"
                      addLabelClasses="required text-right"
                      maxlength="8"
                      :placeholder="$t('label.acronym')" 
                    />
                    </CCol>
                </CRow>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CTextarea
                        v-model="$v.linea.Address.$model"
                        :invalid-feedback="errorMessage($v.linea.Address)"
                        :is-valid="hasError($v.linea.Address)"
                        size="sm"
                        :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                        :label="$t('label.address')"
                        addLabelClasses="required text-right"
                        maxlength="150"
                        :placeholder="$t('label.newShippingLineAddress')" 
                    />
                  </CCol> 
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow>
                <CCol sm="12" class="text-right" >
                    <CButton
                        color="wipe"
                        class="float-right"
                        :disabled="isSubmit"
                        @click="resetForm"
                    >
                        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                    </CButton>
                    <CButton
                        color="add"
                        class="mx-2 float-right"
                        :disabled="isSubmit"
                        @click.stop="submitFile"
                    >
                        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
                    </CButton>
                </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>         
</template>

<script>
import LineaValidations from '@/_validations/linea-naviera/LineaNavieraValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import LineaNavieraMixin from '@/_mixins/linea-naviera';
import PictureInput from '@/components/picture-input';
import { GenerateImgPlaceholder } from '@/_helpers/funciones';

//Data
function data() {
  let _lang = this.$i18n.locale;
  return {
    isSubmit: false,
    Loading: false,
    actualizar: false,
    image: _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg',
    linea: {
      ShippingLineName: '',
      ShippingLineAbbrev: '',
      Address: '',
      PrimaryPhone: '',
      SecondaryPhone: '',
      PrimaryEmail: '',
      SecondaryEmail: '',
      LineRoute: '',
      Color: '#FFF'
    }
  }
}

//Methods
function submitFile() {
  try {
    this.isSubmit = true;
    this.Loading = true;
    this.$v.linea.$touch();

    if (this.$v.linea.$error) {
      this.isSubmit = false;
      this.Loading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let formData = new FormData();
    formData.append('file1', this.linea.LineRoute);
    this.$http.ejecutar('POST', 'shipping-agency-insert', formData)
    .then((response) => {
      this.linea.LineRoute = response.data.data.files[0].path;
      this.submit();
      this.isSubmit = false;
      this.Loading = false;
    }).catch((err) => {
      this.isSubmit = false;
      this.Loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })

  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function submit() {
  try { 
    this.isSubmit = true;
    this.$v.linea.$touch();

    if (this.$v.linea.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    this.linea.PrimaryPhone.replace('-', '');
    this.linea.SecondaryPhone.replace('-', '');
    let metodo = '';
    let ruta = '';
    let LineJson = [];

    if (this.actualizar) {
      LineJson = {
          ShippingLineId    : this.linea.ShippingLineId,
          ShippingLineName  : this.linea.ShippingLineName,
          ShippingLineAbbrev: this.linea.ShippingLineAbbrev, 
          Address           : this.linea.Address, 
          PrimaryPhone      : this.linea.PrimaryPhone, 
          SecondaryPhone    : this.linea.SecondaryPhone, 
          PrimaryEmail      : this.linea.PrimaryEmail, 
          SecondaryEmail    : this.linea.SecondaryEmail, 
          LineRoute         : this.linea.LineRoute,
          Color             : this.linea.Color,
          Status            : this.linea.Status
      };
      metodo = 'PUT';
      ruta = "shipping-agency-update";
    } else {
      LineJson = {
          ShippingLineName  : this.linea.ShippingLineName,
          ShippingLineAbbrev: this.linea.ShippingLineAbbrev, 
          Address           : this.linea.Address, 
          PrimaryPhone      : this.linea.PrimaryPhone, 
          SecondaryPhone    : this.linea.SecondaryPhone, 
          PrimaryEmail      : this.linea.PrimaryEmail, 
          SecondaryEmail    : this.linea.SecondaryEmail, 
          LineRoute         : this.linea.LineRoute,
          Color             : this.linea.Color
      };
      metodo = 'POST';
      ruta = "shipping-agency-insert";
    }

    this.$http.ejecutar(metodo, ruta, LineJson, { root: 'LineJson' })
    .then(response => {
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
      this.$emit('child-refresh',true);
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error" });
  }
}

function resetForm() {
  let _lang = this.$i18n.locale;
  this.linea.ShippingLineName = '';
  this.linea.ShippingLineAbbrev = '';
  this.linea.Address = '';
  this.linea.PrimaryPhone = '';
  this.linea.SecondaryPhone = '';
  this.linea.PrimaryEmail = '';
  this.linea.SecondaryEmail = '';
  this.linea.LineRoute = '';
  this.linea.Color = '#FFF';
  this.actualizar= false;
  this.image = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
  this.$v.$reset();
  if (this.$refs.imageInput) {
    this.$refs.imageInput.removeImage();
    this.$refs.imageInput.value = null;
    this.$refs.imageInput.file = null;
  }
  this.$emit('child-refresh',true);
}
function handleFileUpload(image) {
    this.linea.LineRoute = this.$refs.imageInput.file;
}

function setLineaData(newLinea) {
  this.actualizar = true;
  this.image = newLinea.LineRoute
    ?`${this.$store.getters["connection/getBase"]}${newLinea.LineRoute.replace('public/', '', null, 'i')}`
    :GenerateImgPlaceholder();
  this.linea.ShippingLineId = newLinea.ShippingLineId;
  this.linea.ShippingLineName = newLinea.ShippingLineName;
  this.linea.ShippingLineAbbrev = newLinea.ShippingLineAbbrev;
  this.linea.Address = newLinea.Address;
  this.linea.PrimaryPhone = newLinea.PrimaryPhone;
  this.linea.SecondaryPhone = newLinea.SecondaryPhone;
  this.linea.PrimaryEmail = newLinea.PrimaryEmail;
  this.linea.SecondaryEmail = newLinea.SecondaryEmail;
  this.linea.LineRoute = newLinea.LineRoute;
  this.linea.Color = newLinea.Color;
  this.linea.Status = newLinea.FgActShippingLine?1:0;
  this.titulo = `MODIFICAR LINEA NAVIERA: ${newLinea.ShippingLineName}`;

  this.touchRequiredValues();
}

//Computeds:

export default {
  name: 'add-linea',
  mixins: [LineaNavieraMixin],
 // props: ['value'],
 props: {
  value:null,
 },
  components: {
    PictureInput
  },
  data,
  validations: LineaValidations,
  directives: UpperCase,
  methods: {
    submit,
    resetForm,
    handleFileUpload,
    submitFile,
    setLineaData
  },
  computed: { },
  watch: {
  },
  mounted () {
    if (this.value) {
        this.setLineaData(this.value);
    }
  },
}
</script>
<style scoped>
</style>